<mat-sidenav-container fxLayout="column" style="height: 100%">
  <mat-sidenav fxLayout="column" #sidenav>
    <mat-nav-list>
      <!-- <a
        mat-list-item
        *ngFor="let navLink of getNavLinks()"
        [routerLink]="navLink.link"
      >
        {{ navLink.label }}
      </a> -->
      <ng-container *ngIf="getNavLinks$() | async as navLinks">
        <a
          mat-list-item
          *ngFor="let navLink of navLinks | navFilter : navType"
          [routerLink]="navLink.link"
        >
          {{ navLink.label }}</a
        >
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content fxLayout="column">
    <div fxLayout="column" style="height: 100%">
      <app-header (sideNavOpen)="openSideNav()"></app-header>
      <div class="page-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #accountHeader let-account>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="account-header"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon class="account-icon">account_circle</mat-icon>

      <div *ngIf="account">
        <div class="account-name">{{ account.name }}</div>
        <div>{{ account.username }}</div>
      </div>

      <div *ngIf="!account">
        <div class="account-name">Not Signed In</div>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="signOut; context: context"></ng-container>
    <ng-container *ngTemplateOutlet="signIn; context: context"></ng-container>
  </div>
</ng-template>

<ng-template #signOut let-account="account" let-logout="logout">
  <div matTooltip="Sign Out" *ngIf="account">
    <button
      mat-icon-button
      color="primary"
      (click)="logout()"
      fxShow.lt-sm
      fxHide.gt-xs
    >
      <mat-icon>logout</mat-icon>
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="logout()"
      fxShow.gt-xs
      fxHide.lt-sm
    >
      <span>Sign Out</span>
    </button>
  </div>
</ng-template>

<ng-template #signIn let-account="account" let-login="login">
  <button
    mat-flat-button
    color="primary"
    (click)="login()"
    matTooltip="Sign In"
    *ngIf="!account"
  >
    Sign In
  </button>
</ng-template>
