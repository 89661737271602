import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, catchError, map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { StatusType } from '@dlc/models';
import { AuthService, SnackbarService } from '../index';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class StatusTypeService {

  private statusType: BehaviorSubject<StatusType> = new BehaviorSubject<StatusType>({});

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.subscribeToAccount();
  }

  /**
   * We need to subscribe to the current account and re-init
   * based on whether it is null or not
   */
  private subscribeToAccount(): void {
    this.authService
      .getAccount$()
      .subscribe({
        next: (account: AccountInfo | null) => this.init(account)
      });
  }

  private resetStatus(): void {
    this.statusType.next({});
  }

  /**
   * Fetch the configuration if we have an account.
   * Otherwise, clear the current configuration.
   * Authorization of the request should be handled by the auth interceptor
   * with the current account token.
   */
  init(account: AccountInfo | null): void {
    if (!account)
      return this.resetStatus();

    const url = `${environment.apiUrl}/api/StatusTypes`;

    this.http.get<StatusType>(url)
      .subscribe({
        next: response => {
          this.statusType.next(new StatusType(response));
        },
        error: err => {
          this.resetStatus();
          console.error(err);
        }
      });
  }

  getStatusType$(): Observable<StatusType> {
    return this.statusType.asObservable();
  }

  getStatusTypes$(): Observable<any> {
    const url = `${environment.apiUrl}/api/StatusTypes`;

    return this.http
      .get<TaskType[]>(url)
      .pipe(
        map(data => data)
      );

  }

  postStatusType(data: TaskType): Observable<any> {
    const url = `${environment.apiUrl}/api/StatusType`;

    return this.http.post(url, data).pipe(
      map(data => data),
      catchError((err, _) => {
        this.snackbarService.error(`${err.message}`);
        throw err;
      })
    );
  }

  deleteStatusType(id: string): Observable<any> {
    const url = `${environment.apiUrl}/api/StatusType/${id}`;

    return this.http.delete(url);
  }
}
