import { Agent } from "./agent";

export class JobAgent {
  jobId: number;
  agentId: number;

  agent: Agent | null;

  constructor(init?: Partial<JobAgent>) {
    if (init)
      Object.assign(this, init);
  }
}