import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Config } from '@dlc/models';
import { AuthService } from '../auth/auth.service';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: BehaviorSubject<Config> = new BehaviorSubject<Config>({});

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.subscribeToAccount();
  }

  /**
   * We need to subscribe to the current account and re-init
   * based on whether it is null or not
   */
  private subscribeToAccount(): void {
    this.authService
      .getAccount$()
      .subscribe({
        next: (account: AccountInfo | null) => this.init(account)
      });
  }

  private resetConfig(): void {
    this.config.next({});
  }

  /**
   * Fetch the configuration if we have an account.
   * Otherwise, clear the current configuration.
   * Authorization of the request should be handled by the auth interceptor
   * with the current account token.
   */
  init(account: AccountInfo | null): void {
    if (!account)
      return this.resetConfig();

    const url = `${environment.apiUrl}/api/Configuration`;

    this.http.get<Config>(url)
      .subscribe({
        next: response => {
          this.config.next(new Config(response));
        },
        error: err => {
          this.resetConfig();
          console.error(err);
        }
      });
  }

  getConfiguration$(): Observable<Config> {
    return this.config.asObservable();
  }

  getDynamicConfiguration$(): Observable<any> {
    const url = `${environment.apiUrl}/api/Configuration`;

    return this.http
      .get<Config>(url)
      .pipe(
        map(data => data.databaseConfigurations)
      );

  }

  updateDynamicConfigurations(data: any[]): Observable<any> {
    const url = `${environment.apiUrl}/api/Configuration`;

    return this.http.post(url, data);
  }
}
