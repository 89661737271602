import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { AuthService, NavLinksBuilderService } from "../services";
import { Observable } from 'rxjs';
import { NavLinkType } from '@dlc/enums';
import { NavLink } from '@dlc/classes';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public name: string | null = "";
  public navType = NavLinkType.Header;

  @Output()
  public sideNavOpen: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly authService: AuthService,
    private readonly navLinksBuilderService: NavLinksBuilderService
  ) { }

  ngOnInit(): void {
    this.name = ""; // this.authService.getCurrentUser();
  }

  openSideNav() {
    this.sideNavOpen.emit();
  }

  getNavLinks$(): Observable<NavLink[]> {
    return this.navLinksBuilderService.generateLinks$();
  }

  logout() {
    this.authService.logout();
  }

  get account(): boolean | null {
    return this.authService.isAuthenticated();
  }
}
