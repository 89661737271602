import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, catchError, map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { GraphUserModel } from '@dlc/models';
import { AuthService, SnackbarService } from '../index';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class MsGraphService {

  private graphUserModel: BehaviorSubject<GraphUserModel> = new BehaviorSubject<GraphUserModel>({});

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.subscribeToAccount();
  }

  /**
   * We need to subscribe to the current account and re-init
   * based on whether it is null or not
   */
  private subscribeToAccount(): void {
    this.authService
      .getAccount$()
      .subscribe({
        next: (account: AccountInfo | null) => this.init(account)
      });
  }

  private reset(): void {
    this.graphUserModel.next({});
  }

  /**
   * Fetch the configuration if we have an account.
   * Otherwise, clear the current configuration.
   * Authorization of the request should be handled by the auth interceptor
   * with the current account token.
   */
  init(account: AccountInfo | null): void {
    if (!account)
      return this.reset();

    // const url = `${environment.apiUrl}/api/AzureUsers`;

    // this.http.get<GraphUserModel>(url)
    //   .subscribe({
    //     next: response => {
    //       this.graphUserModel.next(new GraphUserModel(response));
    //     },
    //     error: err => {
    //       this.reset();
    //       console.error(err);
    //     }
    //   });
  }

  getUser$(): Observable<GraphUserModel> {
    return this.graphUserModel.asObservable();
  }

  getUsers$(search?: string): Observable<GraphUserModel> {
    const url = `${environment.apiUrl}/api/AzureUsers?search=${search || ''}`;

    return this.http
      .get<GraphUserModel>(url)
      .pipe(
        map(data => data)
      );

  }
}
