export class StatusType {
  id?: number;
  name?: string;
  followUp?: number;
  reportingStatus?: string;
  active?: boolean;

  constructor(init?: Partial<StatusType>) {
    if (init)
      Object.assign(this, init);
  }
}
