import { MaximoObject } from "./maximo-object";
import { MxDocLink } from "./mx-doc-link";

export class MxUploadFileDocLink extends MaximoObject {

  /**
   * The name of the file.
   */
  public description: string;

  /**
   * A base64 encoding of the file.
   */
  public documentdata: string;

  /**
   * The name of the file.
   */
  public urlname: string;

  private readonly urltype: string = 'FILE';

  private readonly doctype: string = 'Attachments';

  constructor(init?: Partial<MxUploadFileDocLink>) {
    super();

    if (init) {
      Object.assign(this, init);
    }
  }

  static fromDocLinks(doclinks: MxDocLink): MxUploadFileDocLink[] {
    return doclinks.member.map(doclink => {
      return new MxUploadFileDocLink({
        description: doclink.describedBy.description,
        urlname: doclink.describedBy.description
      });
    });
  }
}
