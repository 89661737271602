import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountInfo } from '@azure/msal-browser';
import { AuthService } from '../services';

@Component({
  selector: 'lsa-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss']
})
export class AccountDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public account: AccountInfo,
    public readonly authService: AuthService
  ) {
  }

  logInOrOut(account: AccountInfo | null): void {
    if (account)
      this.authService.logout()
    else
      this.authService.login();
  }
}
