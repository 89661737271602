import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';

import { map, Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { INavLinksBuilderService } from './nav-links-builder.service.interface';
import { NavLink } from '@dlc/models';
import { NavLinkType } from '@dlc/enums';
import { AuthRoles } from '@dlc/classes';
import { AccountDialogComponent } from '@dlc/account-dialog/account-dialog.component';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class NavLinksBuilderService implements INavLinksBuilderService {

  constructor(
    private readonly authService: AuthService,
    private readonly matDialog: MatDialog) { }

  /**
 * Generate nav links.
 * @returns Generated nav links.
 */
  generateLinks$(): Observable<NavLink[]> {
    return this.authService.getAccount$()
      .pipe(
        switchMap(account => {
          if (!account)
            return of(this.generateLinks());

          return this.authService.getRoles$().pipe(
            map((roles: AuthRoles | null) => {
              return this.generateLinks(account, roles);
            })
          );
        })
      );
  }

  /**
   * Generate nav links.
   * @returns Generated nav links.
   */
  generateLinks(account: AccountInfo | null = null, roles: AuthRoles | null = null): NavLink[] {
    const navLinks: NavLink[] = this._getPublicLinks();

    if (this.authService.isAuthenticated()) {
      navLinks.push(...this._getProtectedLinks())
    }
    else {
      navLinks.push(this._getLoginLink());
    }

    if (roles?.hasRole(AuthRoles.Administrator)) {
      navLinks.push({ label: "Configuration", link: '/configurations', type: NavLinkType.Header, icon: "settings" });
      navLinks.push({ label: 'Agents', link: '/agents', type: NavLinkType.Side });
      navLinks.push({ label: 'Engineering Technicians', link: '/engineertechs', type: NavLinkType.Side });
      navLinks.push({ label: 'Grantor Types', link: '/grantorTypes', type: NavLinkType.Side });
      navLinks.push({ label: 'Initiatives', link: '/initiatives', type: NavLinkType.Side });
      navLinks.push({ label: 'Municipalities', link: '/municipalities', type: NavLinkType.Side });
      navLinks.push({ label: 'Project Managers', link: '/projectmanagers', type: NavLinkType.Side });
      navLinks.push({ label: 'Project Priorities', link: '/projectpriorities', type: NavLinkType.Side });
      navLinks.push({ label: 'Railroads', link: '/railroads', type: NavLinkType.Side });
      navLinks.push({ label: 'Service Centers', link: '/servicecenters', type: NavLinkType.Side });
      navLinks.push({ label: 'Status Types', link: '/statusTypes', type: NavLinkType.Side });
      navLinks.push({ label: 'Task Types', link: '/taskTypes', type: NavLinkType.Side });
      navLinks.push({ label: 'Turnaround Times', link: '/turnaroundtimes', type: NavLinkType.Side });
    }

    return navLinks;
  }

  private getAccountItem(): NavLink {
    return new NavLink({
      type: NavLinkType.Header,
      click: this.showAccountDialog(),
      icon: 'account_circle'
    });
  }

  private showAccountDialog() {
    return (event: MouseEvent, matButton: MatButton, matToolbar: MatToolbar) => {
      this.matDialog.open(AccountDialogComponent, {
        width: '400px',
        panelClass: 'account-dialog',
        position: {
          top: (matToolbar._elementRef.nativeElement.clientTop + matToolbar._elementRef.nativeElement.clientHeight).toString() + 'px',
          right: '0px'
        },
        data: this.authService.getAccount()
      })
    }
  }

  // --
  // Util functions.
  // --

  /**
   * Generate public nav links.
   * @returns Public links.
   */
  private _getPublicLinks(): NavLink[] {
    return [
      new NavLink({ label: 'Home', link: '/home', type: NavLinkType.Side })
    ]
  }

  /**
   * Generate protected nav links.
   * @returns Protected links.
   */
  private _getProtectedLinks(): NavLink[] {
    return [
      new NavLink({ label: 'Jobs', link: '/jobs', type: NavLinkType.Side })
    ]
  }

  /**
   * Generate login nav link.
   * @returns Login link.
   */
  private _getLoginLink(): NavLink {
    //return new NavLink({ label: 'Login', link: '/login' });
    return this.getAccountItem();
  }
}
