import { ILoggerService } from '@dlc/interfaces';

export class LocalLoggerService implements ILoggerService {

  trackEvent(eventName: string): void {
    console.log('trackEvent:', eventName);
  }

  trackPageView(uri: string): void {
    console.log('trackPageView:', uri);
  }

  trackTrace(traceMessage: string): void {
    console.log('trackTrace:', traceMessage);
  }

  trackException(exception: Error | string, properties: any = null): void {
    console.error('trackException:', exception, properties || '');
  }
}
