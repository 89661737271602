export class Agent {
  id?: number;
  oid?: string;
  firstName?: string;
  lastName?: string;
  otherName?: string;
  phone?: string;
  email?: string;
  securityCode?: number;
  active?: boolean;
  fullName?: string;

  constructor(init?: Partial<Agent>) {
    if (init)
      Object.assign(this, init);
  }
}