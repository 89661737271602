import { HttpParams } from "@angular/common/http";
import { IPaging } from "../../interfaces";

export class MxParams implements IPaging {
  public paging: boolean;
  public pageSize: number;
  public pageNum: number;

  private _select: string[];
  private _where: string[];
  private _props: string[];
  public _queryName: string | undefined;

  constructor(init: Partial<MxParams> = {}) {

    const defaults = {
      pageSize: 10,
      pageNum: 0,
      _select: [],
      _where: [],
      _props: [],
      _queryName: undefined,
      paging: false,
      collectionCount: false
    };

    Object.assign(this, defaults, init);
  }

  useQuery(queryName: string): void {
    this._queryName = queryName;
  }

  addSelect(...data: string[]): void {
    this._select.push(...data);
  }

  addWhere(...data: string[]): void {
    this._where.push(...data);
  }

  addProperties(...data: string[]): void {
    this._props.push(...data);
  }

  toQueryString(): string {
    return this.toHttpParams().toString();
  }

  toHttpParams(): HttpParams {
    if (this._select.length === 0) {
      this._select.push('*');
    }

    let p: HttpParams = new HttpParams();

    if (!!this._queryName) {
      p = p.set('savedQuery', this._queryName);
    }

    p = p.set('oslc.select', this._select.join(','))
      .set('lean', 1);

    if (this._where.length > 0) {
      p = p.set('oslc.where', this._where.join(' AND '));
    }

    if (this._props.length > 0) {
      p = p.set('oslc.properties', this._props.join(','));
    }

    if (this.paging) {
      p = p.set('oslc.paging', 'true')
        .set('collectioncount', 1)
        .set('oslc.pageSize', this.pageSize)
        .set('pageno', this.pageNum + 1);
    }

    return p;
  }
}
