export interface IMxWorkOrder {
  workorderid: number;
  description: string;
  parent: string;
  status: string;
  status_description: string;
  statusdate: string;
  wopriority: number;
  wonum: string;
  wopriority_description: string | null;
  istask: boolean;

  workorder?: {
    workorderid: number;
    taskid: number;
    status: string;
    status_description: string;
    statusdate: string;
    wopriority: number;
    wopriority_description: string | null;
    description: string;
  }[] | null;
}

export class MxWorkOrder implements IMxWorkOrder {
  public workorderid: number;
  public description: string;
  public parent: string;
  public status: string;
  public status_description: string;
  public statusdate: string;
  public wopriority: number;
  public wonum: string;
  public wopriority_description: string | null;
  public istask: boolean;
  public workorder?: {
    workorderid: number;
    taskid: number;
    status: string;
    status_description: string;
    statusdate: string;
    wopriority: number;
    wopriority_description: string | null;
    description: string;
  }[] | null;

  constructor(init?: Partial<IMxWorkOrder>) {
    if (init)
      Object.assign(this, init);
  }
}
