import { TaskType } from "./task-type";
import { GrantorType } from "./grantor-type";

export class TurnAroundTime {
  taskTypeId?: number;
  grantorTypeId?: number;
  turnAround?: number;

  taskType?: TaskType | null;
  grantorType?: GrantorType | null;

  constructor(init?: Partial<TurnAroundTime>) {
    if (init)
      Object.assign(this, init);
  }
}
