import { DynamicConfig } from "./dynamic-config.model";

export class Config {
  instrumentationKey?: string;
  databaseConfigurations?: DynamicConfig[];

  constructor(init?: Partial<Config>) {
    if (init)
      Object.assign(this, init);
  }
}
