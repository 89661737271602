import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, catchError, map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ProjectPriority } from '@dlc/models';
import { AuthService, SnackbarService } from '../index';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class ProjectPriorityService {

  private projectPriority: BehaviorSubject<ProjectPriority> = new BehaviorSubject<ProjectPriority>({});

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.subscribeToAccount();
  }

  /**
   * We need to subscribe to the current account and re-init
   * based on whether it is null or not
   */
  private subscribeToAccount(): void {
    this.authService
      .getAccount$()
      .subscribe({
        next: (account: AccountInfo | null) => this.init(account)
      });
  }

  private reset(): void {
    this.projectPriority.next({});
  }

  /**
   * Fetch the configuration if we have an account.
   * Otherwise, clear the current configuration.
   * Authorization of the request should be handled by the auth interceptor
   * with the current account token.
   */
  init(account: AccountInfo | null): void {
    if (!account)
      return this.reset();

    // const url = `${environment.apiUrl}/api/ProjectPriorities`;

    // this.http.get<ProjectPriority>(url)
    //   .subscribe({
    //     next: response => {
    //       this.projectPriority.next(new ProjectPriority(response));
    //     },
    //     error: err => {
    //       this.reset();
    //       console.error(err);
    //     }
    //   });
  }

  getProjectPriority$(): Observable<ProjectPriority> {
    return this.projectPriority.asObservable();
  }

  getProjectPriorities$(): Observable<ProjectPriority[]> {
    const url = `${environment.apiUrl}/api/ProjectPriorities`;

    return this.http
      .get<ProjectPriority[]>(url)
      .pipe(
        map(data => data)
      );

  }

  postProjectPriority(data: ProjectPriority): Observable<any> {
    const url = `${environment.apiUrl}/api/ProjectPriority`;

    return this.http.post(url, data)
      .pipe(
        map(data => data),
        catchError((err, _) => {
          this.snackbarService.error(`${err.message}`);
          throw err;
        })
      );
  }
}
