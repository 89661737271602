export class DynamicConfig {
  id: number;
  name: string;
  configValue: string;
  displayName: string;

  constructor(init?: Partial<DynamicConfig>) {
    if (init)
      Object.assign(this, init);
  }
}
