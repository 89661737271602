import { Sort } from '@angular/material/sort';
import { IJobFiltersDates } from '../interfaces';

export class JobFilter {
  searchCriteria: string | null;
  subjectIdentity: string | null;
  status: string[];
  assignedDates: IJobFiltersDates;
  sort: Sort;
}
