import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private readonly OK: string = 'Ok';

  constructor(
    private snackBar: MatSnackBar
  ) {
  }

  alert(message: string, config: MatSnackBarConfig = {}): void {
    this.snackBar.open(message, this.OK, Object.assign({
      // default alert options
    }, config));
  }

  success(message: string, config: MatSnackBarConfig = {}): void {
    this.snackBar.open(message, this.OK, Object.assign({
      panelClass: ['dlc-snackbar--success']
    }, config));
  }

  error(message: string, config: MatSnackBarConfig = {}): void {
    this.snackBar.open(message, this.OK, Object.assign({
      panelClass: ['dlc-snackbar--error']
    }, config));
  }
}
