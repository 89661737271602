import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";

import { MsalModule, MsalRedirectComponent, MsalInterceptor } from "@azure/msal-angular";
import { InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { AuthService } from './services';

import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { environment } from "../environments/environment";
import { protectedResourcesMap } from "@dlc/protected-resources-map";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AccountDialogComponent } from './account-dialog/account-dialog.component';
import { initAppFactory } from '@dlc/init';
import { ApiAuthInterceptor } from '@dlc/interceptors';
import { NavFilterPipe } from '@dlc/pipes';

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: environment.clientId,
    authority: `https://login.microsoftonline.com/${environment.tenant}`,
    redirectUri: `${window.location.origin}`,
    postLogoutRedirectUri: `${window.location.origin}`
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel: LogLevel, msg: string) => (logLevel === LogLevel.Error) ? console.error(msg) : console.log(msg),
      logLevel: environment.msalLogLevel,
      piiLoggingEnabled: !environment.production
    }
  }
});

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AccountDialogComponent,
    NavFilterPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MsalModule.forRoot(publicClientApplication,
      {
        interactionType: InteractionType.Redirect // Guard.
      },
      {
        interactionType: InteractionType.Redirect, // Interceptor
        protectedResourceMap: protectedResourcesMap
      }
    ),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFactory,
      deps: [AuthService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptor,
      multi: true
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000, // show for 5 seconds,
        verticalPosition: 'top'
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
