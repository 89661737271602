import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { NavLink } from './models';
import { NavLinkType } from '@dlc/enums';
import { AuthService, NavLinksBuilderService, LoggerService } from "./services";
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  @ViewChild('sidenav', { static: false })
  public sidenav: MatSidenav;

  navType = NavLinkType.Side;

  constructor(
    private readonly router: Router,
    private readonly loggerService: LoggerService,
    private readonly authService: AuthService,
    private readonly navLinksBuilderService: NavLinksBuilderService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscribeToRouter();
    this.subscribeToBreakpoints();
  }

  private subscribeToRouter(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        distinctUntilChanged((previous: any, current: any) => {
          return previous.url === current.url;
        }
        ))
      .subscribe(() => {
        this.loggerService.trackCurrentPageView();
        this.sidenav.close()
          .then();
        this.cdr.markForCheck();
      });
  }

  private subscribeToBreakpoints(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small
      ])
      .subscribe({
        next: (breakpointState: BreakpointState) => {
          if (!breakpointState.matches && this.sidenav) {
            this.sidenav.close();
            this.cdr.markForCheck();
          }
        }
      });
  }

  getNavLinks(): NavLink[] {
    return this.navLinksBuilderService.generateLinks();
  }

  getNavLinks$(): Observable<NavLink[]> {
    return this.navLinksBuilderService.generateLinks$();
  }

  openSideNav() {
    this.sidenav.open();
  }

  get account(): AccountInfo | null {
    return this.authService.getAccount();
  }

  get context(): any {
    return {
      account: this.account,
      login: () => this.authService.login(),
      logout: () => this.authService.logout()
    }
  }
}
