export class Railroad {
  id?: number;
  name?: string;
  contact?: string;
  address?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  phone?: string;
  email?: string;
  vendorCode?: string;
  blanketAmount?: number;
  active?: boolean;

  constructor(init?: Partial<Railroad>) {
    if (init)
      Object.assign(this, init);
  }
}