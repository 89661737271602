// import { JobTask } from "./job-task";
import { StatusType } from "./status-type";

export class JobTaskAction {
  id?: number;
  jobTaskId?: number;
  actionDate?: string;
  statusTypeId?: number;
  statusOther?: string | null;
  mailingDate?: string | null;
  mailingTrackingNumber?: string | null;
  mailingSentBy?: string | null;
  issuanceExecutionDate?: string | null;
  paymentAmount?: number;
  statePermitNo?: string | null;
  statePermitIssued?: string | null;
  notes?: string;
  createdDate?: string;

  // jobTask?: JobTask | null;
  statusType?: StatusType | null;

  constructor(init?: Partial<JobTaskAction>) {
    if (init)
      Object.assign(this, init);
  }
}