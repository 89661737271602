import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard, AdminGuard } from "./guards";

const routes: Routes = [
  {
    path: '',
    redirectTo: "/home",
    pathMatch: "full"
  },
  {
    path: 'home',
    loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'grantorTypes',
    loadChildren: () => import('./grantor-types/grantor-types.module').then(m => m.GrantorTypeModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'railroads',
    loadChildren: () => import('./railroads/railroads.module').then(m => m.RailroadModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'agents',
    loadChildren: () => import('./agents/agents.module').then(m => m.AgentModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'engineertechs',
    loadChildren: () => import('./engineerTechs/engineerTechs.module').then(m => m.EngineerTechModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'projectmanagers',
    loadChildren: () => import('./projectManagers/projectManagers.module').then(m => m.ProjectManagerModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'servicecenters',
    loadChildren: () => import('./serviceCenters/serviceCenters.module').then(m => m.ServiceCenterModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'municipalities',
    loadChildren: () => import('./municipalities/municipalities.module').then(m => m.MunicipalityModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'initiatives',
    loadChildren: () => import('./initiatives/initiatives.module').then(m => m.InitiativeModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'job/:id',
    loadChildren: () => import('./job/job.module').then(m => m.JobModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'job/:id/:wo',
    loadChildren: () => import('./job/job.module').then(m => m.JobModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'taskTypes',
    loadChildren: () => import('./task-types/task-types.module').then(m => m.TaskTypeModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'statusTypes',
    loadChildren: () => import('./status-types/status-types.module').then(m => m.StatusTypeModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'projectpriorities',
    loadChildren: () => import('./project-priorities/project-priorities.module').then(m => m.ProjectPrioritiesModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'turnaroundtimes',
    loadChildren: () => import('./turn-around-times/turn-around-times.module').then(m => m.TurnAroundTimeModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'configurations',
    loadChildren: () => import('./admin-configurations/admin-configurations.module').then(m => m.AdminConfigurationsModule),
    canActivate: [AdminGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
