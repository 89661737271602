import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SnackbarService } from '../snackbar/snackbar.service';

import { IMxServiceRequest } from '../../models/maximo/mx-service-request';
import { MxParams, MxPerson, IMaximoPersonObject, IMxWorkOrder } from '../../models';
import { MaximoFilter } from '@dlcclasses';

export interface IMaximoPager {
  page: number;
  total: number;
  data: IMxWorkOrder[];
}

@Injectable({
  providedIn: "any"
})
export class MaximoService {
  constructor(
    private readonly http: HttpClient,
    private readonly snackbarService: SnackbarService,
  ) { }

  getWorkOrders(page = 1, pagesize = 10, filter: MaximoFilter) {
    const url = `${environment.apiUrl}/api/mxapiwo`;
    return this.http.post<IMaximoPager>(url, filter, {
      params: {
        page, pagesize
      }
    })
      .pipe(
        map(data => data),
        catchError((err, _) => {
          this.snackbarService.error('Could not fetch Work Orders');
          throw err;
        })
      );
  }

  getWorkOrder(wonum: string) {
    const url = `${environment.apiUrl}/api/mxapiwohier/${wonum}`;
    return this.http.get<IMxWorkOrder[]>(url)
      .pipe(
        map(data => data),
        catchError((err, _) => {
          this.snackbarService.error(`Could not fetch work order ${wonum}`);
          throw err;
        })
      );
  }

  ackWorkOrder(id: number) {
    const url = `${environment.apiUrl}/api/mxapiwo/${id}`;
    return this.http.post<IMxWorkOrder>(url, id)
      .pipe(
        map(data => data),
        catchError((err, _) => {
          this.snackbarService.error(`Could not acknowledge work order ${id}`);
          throw err;
        })
      );
  }

  markPermitComplete(wonum: string) {
    const url = `${environment.apiUrl}/api/mxapiwo/${wonum}`;
    return this.http.put<any>(url, null)
      .pipe(
        map(data => data),
        catchError((err, _) => {
          this.snackbarService.error('Could not mark work order completed');
          throw err;
        })
      );
  }

  getServiceRequest() {
    return this.http.get<any>(`${environment.maximoProxy}mxapisr?lean=1`,
      {
        headers: {
          "content-type": "application/json",
          "properties": "*",
        }
      })
      .pipe(
        map(data => data),
        catchError((err, _) => {
          this.snackbarService.error('Could not fetch Service Requests');
          throw err;
        })
      );
  }

  postServiceRequest(serviceRequest: IMxServiceRequest) {
    return this.http.post<any>(`${environment.maximoProxy}mxapisr?lean=1`,
      serviceRequest,
      {
        headers: {
          "content-type": "application/json",
          "properties": "*",
        }
      })
      .pipe(
        map(data => data),
        catchError((err, _) => {
          this.snackbarService.error('Service Report could not be created');
          throw err;
        })
      );
  }

  getMxPerson(id: string) {
    const params: MxParams = new MxParams();
    params.addWhere(`personid="${id}"`);

    return this.http.get<IMaximoPersonObject>(`${environment.maximoProxy}mxapiperson`,
      { params: params.toHttpParams() })
      .pipe(
        map(data => new MxPerson(data)),
        catchError((err, _) => {
          this.snackbarService.error('unable to fetch user');
          throw err;
        })
      );
  }
}
