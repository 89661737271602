import { Agent } from "./agent";
import { ServiceCenter } from "./service-center";

export class Municipality {
  id?: number;
  name?: string;
  contact?: string;
  address?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  county?: string;
  phone?: string;
  email?: string;
  agentId?: number | null;
  turnAround?: number;
  vendorCode?: string;
  blanketAmount?: number;
  serviceCenterId?: number | null;
  polePermitFee?: number;
  active?: boolean;

  agent?: Agent;
  serviceCenter?: ServiceCenter;

  constructor(init?: Partial<Municipality>) {
    if (init)
      Object.assign(this, init);
  }
}