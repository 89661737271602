import { Injectable } from '@angular/core';
import { Config } from '@dlc/models';
import { ILoggerService } from '@dlc/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { AppInsightsLoggerService } from './app-insights-logger.service';
import { environment } from '../../../environments/environment';
import { ConfigService, LocalLoggerService } from '..';

@Injectable({
  providedIn: 'root'
})
export class LoggerService implements ILoggerService {

  /**
   * A list of loggers used to distribute logging capabilities to any service
   */
  private loggers: ILoggerService[];

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly configService: ConfigService
  ) {
    this.init({});
    this.subscribeToConfig();
  }

  private subscribeToConfig(): void {
    this.configService
      .getConfiguration$()
      .subscribe((config: Config) => this.init(config))
  }

  public init(config: Config): void {
    this.loggers = [];

    if (environment.local)
      this.loggers.push(new LocalLoggerService());

    if (config.instrumentationKey)
      this.loggers.push(new AppInsightsLoggerService(config.instrumentationKey));
  };

  trackTrace(traceMessage: string): void {
    this.loggers.forEach(logger => logger.trackTrace(traceMessage));
  }

  trackCurrentPageView(): void {
    this.trackPageView(this.router.url);
  }

  trackPageView(uri: string): void {
    this.loggers.forEach(logger => logger.trackPageView(uri));
  }

  trackEvent(eventName: string): void {
    this.loggers.forEach(logger => logger.trackEvent(eventName));
  }

  trackException(exception: Error | string, properties: any = null): void {
    this.loggers.forEach(logger => logger.trackException(exception, properties));
  }

  /**
   * Attempt to convert an incoming exception to a proper Error object
   * @param {any} exception
   */
  public static toError(exception: any): Error {
    switch (true) {
      case typeof (exception) === typeof (Error):
        return exception;
      case typeof (exception) === 'string':
        return new Error(exception);
      default:
        return new Error(JSON.stringify(exception));
    }
  }
}
