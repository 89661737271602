import { ILoggerService } from '@dlc/interfaces';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { LoggerService } from '..';

export class AppInsightsLoggerService implements ILoggerService {

  private appInsights: ApplicationInsights;

  constructor(
    private readonly appInsightsKey: string
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.appInsightsKey!
      }
    });
    this.appInsights.loadAppInsights();
  }

  trackEvent(eventName: string): void {
    this.appInsights.trackEvent({ name: eventName });
  }

  trackPageView(uri: string): void {
    this.appInsights.trackPageView({ uri })
  }

  trackTrace(traceMessage: string): void {
    this.appInsights.trackTrace({ message: traceMessage })
  }

  trackException(exception: Error | string, properties: any = null): void {
    exception = LoggerService.toError(exception);
    this.appInsights.trackException({ exception, properties })
  }
}
