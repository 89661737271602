import { Agent } from "./agent";
import { GrantorType } from "./grantor-type";
import { JobTaskAction } from "./job-task-action";
import { Municipality } from "./municipality";
import { Railroad } from "./railroad";
import { TaskType } from "./task-type";
import { TurnAroundTime } from "./turn-around-time";

export class JobTask {
  id?: number;
  jobId?: number;
  receivedDate?: string;
  agentId?: number;
  taskTypeId?: number | null;
  taskTypeOther?: string | null;
  grantorTypeId?: number | null;
  railroadId?: number | null;
  municipalityId?: number | null;
  sheetTNumber?: string;
  itemNumber?: string;
  ownerName?: string;
  otherName?: string;
  address?: string;
  phone?: string;
  extension?: string;
  email?: string;
  propertyIdNumber?: string;
  pole?: boolean;
  anchor?: boolean;
  vegetationManagement?: boolean;
  railroadCrossing?: boolean;
  conduit?: boolean;
  aerialLine?: boolean;
  comment?: string;
  deleted?: boolean;

  agent?: Agent | null;
  taskType?: TaskType | null;
  grantorType?: GrantorType | null;
  railroad?: Railroad | null;
  municipality?: Municipality | null;
  turnAroundTime?: TurnAroundTime | null;
  jobTaskActions?: JobTaskAction[] | null;

  constructor(init?: Partial<JobTask>) {
    if (init)
      Object.assign(this, init);
  }
}