export class AuthRoles {

  static readonly Administrator = 'Administrator';

  public roles: string[];

  constructor(
    roles: string[]
  ) {
    this.roles = roles;
  }

  hasRole(role: string): boolean {
    return this.roles.includes(role);
  }
}
