import { Pipe, PipeTransform } from '@angular/core';
import { NavLink } from '@dlc/classes';
import { NavLinkType } from '@dlc/enums';

@Pipe({ name: 'navFilter' })
export class NavFilterPipe implements PipeTransform {
  transform(navLinks: NavLink[], ...types: NavLinkType[]): NavLink[] {
    return navLinks.filter(navLink => navLink.type === undefined || types.indexOf(navLink.type) !== -1);
  }
}
