import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, catchError, map, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { EngineerTech } from '@dlc/models';
import { AuthService, SnackbarService } from '../index';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class EngineerTechService {

  private engineerTech: BehaviorSubject<EngineerTech> = new BehaviorSubject<EngineerTech>({});

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private readonly snackbarService: SnackbarService,
  ) {
    this.subscribeToAccount();
  }

  /**
   * We need to subscribe to the current account and re-init
   * based on whether it is null or not
   */
  private subscribeToAccount(): void {
    this.authService
      .getAccount$()
      .subscribe({
        next: (account: AccountInfo | null) => this.init(account)
      });
  }

  private reset(): void {
    this.engineerTech.next({});
  }

  /**
   * Fetch the configuration if we have an account.
   * Otherwise, clear the current configuration.
   * Authorization of the request should be handled by the auth interceptor
   * with the current account token.
   */
  init(account: AccountInfo | null): void {
    if (!account)
      return this.reset();

    const url = `${environment.apiUrl}/api/EngineerTechs`;

    this.http.get<EngineerTech>(url)
      .subscribe({
        next: response => {
          this.engineerTech.next(new EngineerTech(response));
        },
        error: err => {
          this.reset();
          console.error(err);
        }
      });
  }

  getEngineerTech$(): Observable<EngineerTech> {
    return this.engineerTech.asObservable();
  }

  getEngineerTechs$(): Observable<any> {
    const url = `${environment.apiUrl}/api/EngineerTechs`;

    return this.http
      .get<EngineerTech[]>(url)
      .pipe(
        map(data => data)
      );

  }

  postEngineerTech(data: EngineerTech): Observable<any> {
    const url = `${environment.apiUrl}/api/EngineerTech`;

    return this.http.post(url, data).pipe(
      map(data => data),
      catchError((err, _) => {
        this.snackbarService.error(`${err.message}`);
        throw err;
      })
    );
  }
}
