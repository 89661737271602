import { AuthService } from "./services";
import { AccountInfo } from '@azure/msal-browser';

/**
 * @param authService
 */
export function initAppFactory(authService: AuthService): () => Promise<boolean> {
  return () => {
    return new Promise<boolean>((resolve) => {
      // init msal active account
      return authService.init()
        .subscribe({
          next: (account: AccountInfo | null) => {
            resolve(true);
          }
        });
    })
  };
}
