import { GraphUser } from "./graph-user";

export class GraphUserModel {
  "@odata.type"?: string;
  value?: GraphUser[];

  constructor(init?: Partial<GraphUserModel>) {
    if (init)
      Object.assign(this, init);
  }
}