import { Agent } from "./agent";
import { EngineerTech } from "./engineer-tech";
import { Initiative } from "./initiative";
import { JobAgent } from "./job-agent";
import { JobTask } from "./job-task";
import { ProjectManager } from "./project-manager";
import { ProjectPriority } from "./project-priority";

export class Job {
  id?: number;
  jobNumber?: string;
  maximoNumber?: string;
  jobName?: string;
  agentId?: number | null;
  agentAssignedDate?: string;
  engineerTechId?: number | null;
  constructionStartDate?: string;
  initiativeId?: number | null;
  projectManagerId?: number | null;
  tracking?: boolean;
  priorityId?: number | null;
  jobStatus?: number;
  jobStatusText?: string;

  jobAgents?: JobAgent[];
  engineerTech?: EngineerTech | null;
  projectManager?: ProjectManager | null;
  initiative?: Initiative | null;
  jobTasks?: JobTask[];
  projectPriority?: ProjectPriority;

  constructor(init?: Partial<Job>) {
    if (init)
      Object.assign(this, init);
  }
}