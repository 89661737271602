import { MaximoObject } from "./maximo-object";

export class MxUploadImageDocLink extends MaximoObject {

  /**
   * The name of the image
   */
  public description: string;

  /**
   * A base64 encoding of the image.
   */
  public documentdata: string;

  private readonly urltype: string = 'FILE';

  private readonly doctype: string = 'Images';

  constructor(init?: Partial<MxUploadImageDocLink>) {
    super();

    if (init) {
      Object.assign(this, init);
    }
  }
}
