import { NavLinkType } from '@dlc/enums';

export class NavLink {
  public type?: NavLinkType
  public label?: string;
  public link?: string;
  public icon?: string;
  public click?: Function;

  constructor(init?: Partial<NavLink>) {
    if (init)
      Object.assign(this, init);
  }

}
