<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  #toolbarRef
>
  <div>
    <button
      (click)="openSideNav()"
      mat-icon-button
      aria-label="Side Nav Button"
    >
      <mat-icon>menu</mat-icon>
    </button>

    <a class="root-link" routerLink="/">Permitting Tool</a>
  </div>

  <div class="nav-menu" fxLayout="row" fxHide.lt-md fxShow.gt-sm>
    <mat-nav-list class="main-nav" fxLayout="row">
      <ng-container *ngIf="getNavLinks$() | async as navLinks">
        <span *ngFor="let navLink of navLinks | navFilter : navType">
          <ng-container *ngIf="navLink.link">
            <ng-container
              *ngTemplateOutlet="linkTpl; context: { $implicit: navLink }"
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="navLink.click">
            <ng-container
              *ngTemplateOutlet="buttonTpl; context: { $implicit: navLink }"
            ></ng-container>
          </ng-container>
        </span>
      </ng-container>
    </mat-nav-list>

    <div>
      {{ name }}
      <span matTooltip="Sign Out" *ngIf="account">
        <button mat-icon-button color="black" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>
      </span>
    </div>
  </div>
</mat-toolbar>

<ng-template #linkTpl let-navLink>
  <a
    [routerLink]="navLink.link"
    *ngIf="navLink.label"
    mat-list-item
    [title]="navLink.label"
  >
    <mat-icon *ngIf="navLink.icon">{{ navLink.icon }}</mat-icon>
    <!-- {{ navLink.label }} -->
  </a>
</ng-template>

<ng-template #buttonTpl let-navLink>
  <div mat-list-item>
    <button
      mat-icon-button
      #buttonRef
      (click)="navLink.click($event, buttonRef, toolbarRef)"
    >
      <mat-icon>{{ navLink.icon }}</mat-icon>
    </button>
  </div>
</ng-template>
