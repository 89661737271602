export class AuthToken {
  public aud: string;
  public iss: string;
  public iat: number;
  public nbf: number;
  public exp: number;
  public name: string;
  public nonce: string;
  public oid: string;
  public preferred_username: string;
  public rh: string;
  public roles: string[] | null;
  public sub: string;
  public tid: string;
  public uti: string;
  public ver: string;

  constructor(init?: Partial<AuthToken>) {
    if (init)
      Object.assign(this, init);
  }
}
