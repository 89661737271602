import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { first, Observable } from 'rxjs';

import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiAuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.toLowerCase().indexOf(environment.apiUrl.toLowerCase()) === -1)
      return next.handle(request);

    return this.authService
      .getToken$()
      .pipe(
        first(),
        switchMap((token: string | null) =>
          next.handle(
            request.clone({
              headers: request.headers.set('Authorization', `Bearer ${token}`)
            })))
      );
  }
}
