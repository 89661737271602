export interface IMxPerson {
  // addressline: string;
  // city: string;
  // country: string;
  displayname: string;
  // firstname: string;
  // lastname: string;
  locationorg: string;
  locationsite: string;
  personid: string;
  personuid: number;
  primaryemail: string;
  // postalcode: string;
  // stateprovince: string;
  status: string;
  status_description: string;
  statusdate: string;
}

export interface IMaximoPersonObject {
  member: IMxPerson | IMxPerson[];
  href: string;
  responseInfo: any;
}

export class MxPerson implements IMxPerson {
  constructor(obj: IMaximoPersonObject) {

    if (Array.isArray(obj.member)) {
      const person = obj.member[0];
      console.log(person);

      //this.addressline = person.addressline;
      //this.city = person.city;
      //this.country = person.country;
      this.displayname = person.displayname;
      //this.firstname = person.firstname;
      //this.lastname = person.lastname;
      this.locationorg = person.locationorg;
      this.locationsite = person.locationsite;
      this.personid = person.personid;
      this.personuid = person.personuid;
      this.primaryemail = person.primaryemail;
      //this.postalcode = person.postalcode;
      //this.stateprovince = person.stateprovince;
      this.status = person.status;
      this.status_description = person.status_description;
      this.statusdate = person.statusdate;
    }
  }
  // public addressline: string;
  // public city: string;
  // public country: string;
  public displayname: string;
  // public firstname: string;
  // public lastname: string;
  public locationorg: string;
  public locationsite: string;
  public personid: string;
  public personuid: number;
  public primaryemail: string;
  public postalcode: string;
  //public stateprovince: string;
  public status: string;
  public status_description: string;
  public statusdate: string;
}