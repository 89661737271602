export class GraphUser {
  id?: string;
  displayName?: string;
  mail?: string;
  surname?: string;
  givenName?: string;
  userPrincipalName?: string;
  jobTitle?: string;
  mobilePhone?: string;
  businessPhones?: string[];

  constructor(init?: Partial<GraphUser>) {
    if (init)
      Object.assign(this, init);
  }
}