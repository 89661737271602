import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { BehaviorSubject, catchError, filter, from, map, Observable, of, switchMap, tap } from "rxjs";
import { AccountInfo, AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { IInitService } from '@dlc/interfaces';
import jwtDecode from 'jwt-decode';
import { AuthToken, AuthRoles } from '@dlc/classes';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IInitService {

  /**
   * This is a local cache copy of the currently active account as an observable.
   * That way, we can subscribe to changes on the observable and update the UI accordingly.
   */
  private activeAccount: BehaviorSubject<AccountInfo | null> = new BehaviorSubject<AccountInfo | null>(null);

  /**
   * This is a local cache copy of the auth result for the currently active account.
   * It can be used to retrieve auth information about the current user, like the access token.
   */
  private authResult: BehaviorSubject<AuthenticationResult>;

  constructor(
    private readonly router: Router,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly location: Location
  ) {
  }

  /**
   * This should be called by the app init factory
   */
  init(): Observable<AccountInfo | null> {
    this.subscribeToLoginSuccess();

    // This will attempt to init the active account from the cache
    return this.initWithRedirect();
  }

  /**
   * Subscribe to successful login event to initialize the active account
   */
  private subscribeToLoginSuccess(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe({
        next: () => this.initWithRedirect()
      }
      );
  }

  private initWithRedirect(): Observable<AccountInfo | null> {
    const init$ = this.initActiveAccount();
    init$.pipe(
      switchMap(() => {
        return this.hasRole$(AuthRoles.Administrator);
      }),
    ).subscribe({
      next: (hasAdministratorRole: boolean) => {

        if (this.location.path() !== '') {
          // don't redirect if they are not viewing the home page
          return;
        }
        return this.router.navigateByUrl('/home');
      }
    });

    return init$;
  }

  /**
   * Initialize the active account observable from the msal cache
   */
  private initActiveAccount(): Observable<AccountInfo | null> {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      this.setAccount(activeAccount);
      return of(activeAccount);
    }

    const accounts = this.msalService.instance.getAllAccounts();
    if (!accounts.length)
      return of(null);

    this.setAccount(accounts[0]);
    return of(accounts[0]);
  }

  /**
     * Checks for the existence of the api key.
     * @returns True if authenticated; false otherwise.
     */
  isAuthenticated(): boolean {
    return !!this.getCurrentUser();
  }

  getCurrentUser(): string | null {
    let usr: string | null = null;
    this.getAuthToken$().subscribe((value) => {
      usr = value.name;
    });

    return usr || null;
  }

  canImpersonate$(): Observable<boolean> {
    return this.hasRole$(AuthRoles.Administrator);
  }

  hasRole$(role: string): Observable<boolean> {
    return this.getRoles$().pipe(
      map((roles: AuthRoles | null) => {
        return roles?.hasRole(role) ?? false;
      })
    )
  }

  getRoles$(): Observable<AuthRoles | null> {
    return this.getAuthToken$().pipe(
      map((token: AuthToken) => token.roles ? new AuthRoles(token.roles) : null)
    );
  }

  /**
   * Get the active account.
   * @returns The active account or null if none exists.
   */
  getAccount(): AccountInfo | null {
    return this.activeAccount.getValue();
  }

  /**
   * Get the active account.
   * @returns The active account or null if none exists.
   */
  getAccount$(): Observable<AccountInfo | null> {
    return this.activeAccount.asObservable();
  }

  /**
   * Set the current active account and cache a copy of it ourselves.
   */
  setAccount(accountInfo: AccountInfo | null): void {
    this.msalService.instance.setActiveAccount(accountInfo)
    this.activeAccount.next(accountInfo);
  }

  getAuthToken$(): Observable<AuthToken> {
    return this.getToken$().pipe(
      map(token => new AuthToken(jwtDecode(token)))
    );
  }

  /**
   * Gets the auth token for the current active account
   */
  getToken$(): Observable<string> {
    // return new Observable<string>(o => o.next("FAKE_TOKEN"));
    return this.getAccount$()
      .pipe(
        switchMap((activeAccount: AccountInfo | null) => {
          return this.getAuthResult$(activeAccount!)
            .pipe(
              switchMap((authResult: AuthenticationResult) => of(authResult!.idToken)),
              catchError(e => {
                // console.log(e)
                return of(e);
              })
            );
        })
      )
  }

  /**
   * Convenience function to retrieve locally cached copy of the auth result.
   * @param account
   * @private
   */
  private getCachedAuthResult(account: AccountInfo): Observable<AuthenticationResult> | null {
    const authResult = this.authResult?.getValue();
    if (
      authResult &&
      AuthService.isActiveAccountAuthResult(authResult, account) &&
      !AuthService.isAuthResultExpired(authResult)
    ) {
      return this.authResult;
    }

    return null;
  }

  private static isActiveAccountAuthResult(authResult: AuthenticationResult, account: AccountInfo): boolean {
    return authResult?.account?.username === account.username;
  }

  private static isAuthResultExpired(authResult: AuthenticationResult): boolean {
    return !!authResult.expiresOn && authResult.expiresOn < new Date();
  }

  /**
   * Get the recent auth result for the current account.
   * We first try to get this from our local cache.
   * If we don't have a locally cached copy, we retrieve a new one from msal.
   * @param account the account to get the
   */
  getAuthResult$(account: AccountInfo): Observable<AuthenticationResult> {
    const cachedAuthResult = this.getCachedAuthResult(account);
    if (cachedAuthResult)
      return cachedAuthResult;

    const request = {
      scopes: ['user.read'],
      account: account
    };
    return from(this.msalService.instance.acquireTokenSilent(request))
      .pipe(
        tap((authResult: AuthenticationResult) => {
          this.authResult = new BehaviorSubject<AuthenticationResult>(authResult);
        }),
        catchError((e, _) => {
          if (AuthService.requiresReAuth(e.errorCode)) {
            this.msalService.acquireTokenRedirect(request);
          }
          throw e;
        })
      );
  }

  private static requiresReAuth(errorCode: string): boolean {
    return errorCode === 'login_required' ||
      errorCode === 'interaction_required' ||
      errorCode === 'consent_required' ||
      errorCode === 'invalid_grant';
  }

  /**
   * Login against Azure AD via a redirect.
   */
  login(): void {
    this.msalService.loginRedirect();
  }

  /**
   * Logout against Azure AD via a redirect.
   */
  logout(): Observable<void> {
    return this.msalService.logout();
  }
}
