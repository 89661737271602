<div mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutAlign="center center">
      <ng-container *ngIf="account; else signedOutHeader">
        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div class="account-name">{{account.name}}</div>
          <div>{{account.username}}</div>
        </div>
      </ng-container>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-flat-button color="primary" (click)="logInOrOut(account)">
        {{account? 'Sign Out' : 'Sign In'}}
      </button>
    </div>
  </div>

</div>

<ng-template #signedOutHeader>
  <div class="account-name">Not Signed In</div>
</ng-template>
